import React, { useRef } from 'react'
import ReactSlickSlider from 'react-slick'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCommentsAlt,
  faPhoneLaptop,
  faLongArrowRight,
  faPercent,
  faChartLine,
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-light-svg-icons'

import SiteLayout from '../layout/SiteLayout'
import Container from '../components/Container'
import Bubbles from '../components/Bubbles'
import Box from '../components/Box'
import SEO from '../components/SEO'
import styles from './index.module.scss'
import heroImg from '../images/svg/home/hero.svg'
import startUps from '../images/svg/home/start-ups.svg'
import smallBusinesses from '../images/svg/home/small-businesses.svg'
import soleTraders from '../images/svg/home/sole-traders.svg'
import skyrocket from '../images/svg/home/skyrocket.svg'
import numbers from '../images/svg/home/numbers.svg'
import dkTuning from '../images/png/home/dk-tuning.png'
import acmsWaste from '../images/png/home/acms-waste.png'
import atSheds from '../images/png/home/at-sheds.png'
import xero from '../images/svg/home/xero.svg'
import gocardless from '../images/svg/home/gocardless.svg'
import fundingCircle from '../images/svg/home/funding-circle.svg'
import receiptBank from '../images/svg/home/receipt-bank.svg'
import futrli from '../images/svg/home/futrli.svg'
import transferwise from '../images/svg/home/transferwise.svg'
import stripe from '../images/svg/home/stripe.svg'
import shopify from '../images/svg/home/shopify.svg'

const IndexPage = () => {
  const sliderRef = useRef(null)
  const sliderSettings = {
    arrows: false,
    autoplay: true,
    autoplaySpeed: 8000,
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
  }

  return (
    <SiteLayout header="dark">
      <SEO title="Home" />
      <section className="bg-green-light relative">
        <Container>
          <Bubbles />
          <div className={styles.hero}>
            <div className={styles.heroCta}>
              <h1>Modern accountants for a modern world</h1>
              <p>
                Small businesses need access to up-to-date information, the best
                advice and a bookkeeping system that doesn’t get in the way.
                That’s where we come in.
              </p>
              <div>
                <Link
                  className="btn btn-primary-light m-2"
                  to="/pricing/small-business-accounting"
                >
                  View pricing
                </Link>
                <Link
                  className="btn btn-green-light m-2"
                  to="/how-it-works/small-businesses"
                >
                  Learn more
                </Link>
              </div>
            </div>
            <div className={styles.heroImgContainer}>
              <img
                className={styles.heroImg}
                src={heroImg}
                alt="small business accountants"
              />
            </div>
          </div>
        </Container>
      </section>
      <section className="bg-white">
        <Container>
          <div className={styles.whoWeHelp}>
            <div className={styles.intro}>
              <h2>Who we help</h2>
              <p>
                It’s our honour to serve those who make up the 99% of all
                businesses in the UK. The family-owned businesses, the people
                trying to earn an honest living, the ambitious entrepenuer with
                a vision, we support them all.
              </p>
            </div>
            <div className={styles.boxes}>
              <Box className={styles.box} to="/how-it-works/start-ups">
                <img
                  className={styles.boxImg}
                  src={startUps}
                  alt="Start up accountants"
                />
                <div>
                  <h5 className={styles.boxHeader}>Start ups</h5>
                  <p className={styles.boxText}>
                    Those businesses who are just getting up and running or who
                    have been trading for less than 2 years.
                  </p>
                </div>
                <FontAwesomeIcon
                  className={styles.arrow}
                  icon={faLongArrowRight}
                  size="1x"
                />
              </Box>
              <Box className={styles.box} to="/how-it-works/small-businesses">
                <img
                  className={styles.boxImg}
                  src={smallBusinesses}
                  alt="Small business accountants"
                />
                <div>
                  <h5 className={styles.boxHeader}>Small businesses</h5>
                  <p className={styles.boxText}>
                    More established businesses that have been trading for
                    longer than 2 years, but have less than 50 staff or £10m
                    turnover.
                  </p>
                </div>
                <FontAwesomeIcon
                  className={styles.arrow}
                  icon={faLongArrowRight}
                  size="1x"
                />
              </Box>
              <Box className={styles.box} to="/how-it-works/sole-traders">
                <img
                  className={styles.boxImg}
                  src={soleTraders}
                  alt="Sole trader accountants"
                />
                <div>
                  <h5 className={styles.boxHeader}>Sole traders</h5>
                  <p className={styles.boxText}>
                    One-man-bands who work for themselves and don’t plan to
                    employ any staff, such as freelancers and subcontractors.
                  </p>
                </div>
                <FontAwesomeIcon
                  className={styles.arrow}
                  icon={faLongArrowRight}
                  size="1x"
                />
              </Box>
            </div>
            <div className="d-block text-center">
              <Link
                className="btn btn-primary-light"
                to="/how-it-works/switching-accountants"
              >
                Switching accountants?
              </Link>
            </div>
          </div>
        </Container>
      </section>
      <section className="bg-white">
        <Container>
          <div className={styles.skyrocket}>
            <div className={styles.textContainer}>
              <h2 className={styles.title}>
                Helping you skyrocket your small business.
              </h2>
              <p className={styles.subtitle}>
                Make better decisions for your small business by having the
                support and tools you need from an accountant you can trust.
              </p>
              <div className={styles.iconText}>
                <div className={`${styles.icon} ${styles.iconPrimary}`}>
                  <FontAwesomeIcon icon={faCommentsAlt} size="1x" />
                </div>
                <div>
                  <h3 className={styles.subheading}>Unlimited expert advice</h3>
                  <p>
                    Answers to all your accounting and tax questions at no extra
                    cost.
                  </p>
                </div>
              </div>
              <div className={styles.iconText}>
                <div className={`${styles.icon} ${styles.iconOrange}`}>
                  <FontAwesomeIcon icon={faPhoneLaptop} size="1x" />
                </div>
                <div>
                  <h3 className={styles.subheading}>Market-leading software</h3>
                  <p>
                    Easy to use apps that save time and make bookkeeping a
                    breeze.
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.imgContainer}>
              <span className={styles.circle} />
              <span className={styles.circleTwo} />
              <img src={skyrocket} alt="skyrocket your business" />
            </div>
          </div>
        </Container>
      </section>
      <section className="bg-white">
        <Container>
          <div className={styles.clearPicture}>
            <div className={styles.imgContainer}>
              <span className={styles.circle} />
              <span className={styles.circleTwo} />
              <img src={numbers} alt="accounting reports" />
            </div>
            <div className={styles.textContainer}>
              <h2 className={styles.title}>
                Giving you a clear picture on all things numbers.
              </h2>
              <p className={styles.subtitle}>
                Keep a close eye on the finances with useful reports that make
                sense to you and your business.
              </p>
              <div className={styles.iconText}>
                <div className={`${styles.icon} ${styles.iconPrimary}`}>
                  <FontAwesomeIcon icon={faPercent} size="1x" />
                </div>
                <div>
                  <h3 className={styles.subheading}>
                    Track your profit (or loss)
                  </h3>
                  <p>
                    Regular reports on your small business’s performance so you
                    stay on track.
                  </p>
                </div>
              </div>
              <div className={styles.iconText}>
                <div className={`${styles.icon} ${styles.iconOrange}`}>
                  <FontAwesomeIcon icon={faChartLine} size="1x" />
                </div>
                <div>
                  <h3 className={styles.subheading}>Inspect your cash flow</h3>
                  <p>
                    Tackle cash flow issues before they strike, not when it’s
                    too late.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section className="bg-primary">
        <div className={styles.freeCompany}>
          <span className={styles.bg} />
          <span className={styles.bgRocket} />
          <div className={styles.textContainer}>
            <h3>Starting a new business?</h3>
            <p>
              We'll register a limited company for you free of charge when you
              sign up to our small business accounting service. We'll also
              register you for all the right taxes for total peace of mind.
            </p>
            <Link className="btn btn-green" to="/how-it-works/start-ups">
              Learn more
            </Link>
          </div>
        </div>
      </section>
      <section className="bg-white">
        <Container>
          <div className={styles.testimonials}>
            <div className={styles.textContainer}>
              <h2>Loved by all kinds of small businesses.</h2>
              <p>
                We pride ourselves on delivering an exceptional service. Here's
                what some of our clients had to say.
              </p>
              <div className={styles.buttons}>
                <button
                  className={styles.left}
                  onClick={() => sliderRef.current.slickPrev()}
                  type="button"
                >
                  <FontAwesomeIcon icon={faChevronLeft} size="1x" />
                </button>
                <button
                  className={styles.right}
                  onClick={() => sliderRef.current.slickNext()}
                  type="button"
                >
                  <FontAwesomeIcon icon={faChevronRight} size="1x" />
                </button>
              </div>
            </div>
            <div className={styles.testimonialContainer}>
              <div className={styles.sliderBackground}>
                <ReactSlickSlider ref={sliderRef} {...sliderSettings}>
                  <div>
                    <div className={styles.testimonial}>
                      <img src={dkTuning} alt="DK Tuning logo" />
                      <p className={styles.text}>
                        We have used AirAccounting for the past 3 years and the
                        support and advice is second to none, without which my
                        business would be nowhere near it is today.
                      </p>
                      <p className={styles.name}>Daniel Kirk</p>
                      <p className={styles.company}>DK Tuning Ltd</p>
                    </div>
                  </div>
                  <div>
                    <div className={styles.testimonial}>
                      <img src={atSheds} alt="AT Sheds logo" />
                      <p className={styles.text}>
                        Our books are always kept up-to-date, our accountant is
                        always available when we need him, and everything is
                        explained in a clear and easy to understand way.
                      </p>
                      <p className={styles.name}>Alan Fradgley</p>
                      <p className={styles.company}>AT Sheds & Fencing Ltd</p>
                    </div>
                  </div>
                  <div>
                    <div className={styles.testimonial}>
                      <img src={acmsWaste} alt="ACMS Waste logo" />
                      <p className={styles.text}>
                        AirAccounting promised a speedy and efficient service,
                        which they exceeded my expectations. They are
                        approachable, proactive and friendly whilst doing a
                        quality job.
                      </p>
                      <p className={styles.name}>Luke & Jemma</p>
                      <p className={styles.company}>ACMS Waste Ltd</p>
                    </div>
                  </div>
                </ReactSlickSlider>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section className="bg-white">
        <Container>
          <div className={styles.partners}>
            <h2>We've partnered with the best.</h2>
            <div className={styles.images}>
              <a
                href="https://xero.co.uk"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={xero} alt="Xero accountants" />
              </a>
              <a
                href="https://gocardless.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={gocardless} alt="GoCardless partner" />
              </a>
              <a
                href="https://www.fundingcircle.com/uk/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={fundingCircle} alt="Funding Circle broker" />
              </a>
              <a
                href="https://www.receipt-bank.com/uk/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={receiptBank} alt="Receipt Bank accountants" />
              </a>
              <a
                href="https://stripe.com/gb"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={stripe} alt="Stripe accountants" />
              </a>
              <a
                href="https://www.shopify.co.uk/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={shopify} alt="Shopify accountants" />
              </a>
              <a
                href="https://transferwise.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={transferwise} alt="Transferwise partners" />
              </a>
              <a
                href="https://www.futrli.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={futrli} alt="Futrli accountants" />
              </a>
            </div>
          </div>
        </Container>
      </section>
      <section className="bg-primary">
        <Container>
          <div className={styles.getStarted}>
            <h2>
              Ready to get started?
              <br />
              Let's build your package
            </h2>
            <div>
              <Link
                className="btn btn-primary-invert btn-lg"
                to="/pricing/build-your-package"
              >
                Build your package
              </Link>
            </div>
          </div>
        </Container>
      </section>
    </SiteLayout>
  )
}

export default IndexPage
