import React from 'react'
import { Link } from 'gatsby'

import cn from 'classnames'
import styles from './Box.module.scss'

const Box = ({ className, children, to, ...rest }) => {
  const classNames = cn(styles.box, className)

  if (to) {
    return (
      <Link className={classNames} to={to} {...rest}>
        {children}
      </Link>
    )
  }

  return (
    <div className={classNames} {...rest}>
      {children}
    </div>
  )
}

export default Box
